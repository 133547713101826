import React from "react";
import { css } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

const pageStyles = css`
  max-width: 600px;
  margin: auto;
`;

const RoadMap = () => (
  <Layout>
    <SEO title="Roadmap" />
    <div css={pageStyles}>
      <h1>Roadmap</h1>
      <p>
        If you're seeing this page and not one of our developers, sorry for the
        mess. We plan to put a pretty roadmap here, but for now it's more of a
        development notepad.
      </p>
      <ul>
        <li>
          Add criteria to distinguish fine dining, casual, fast food, etc...{" "}
        </li>
        <li>
          Decide how to tag businesses with no particular focus (generic
          restaurants, full menus)
          <ul>
            <li>
              Add notes to explain unclear tags (What does Ready-To-Eat mean?)
            </li>
          </ul>
        </li>
        <li>Show store payment options.</li>
        <li>Text search</li>
        <li>Tag for healthcare discount? Featured tag?</li>
        <li>Map View Page</li>
        <li>
          Figure out how to sort/display businesses without standard hours. For
          example, "Bitten" is open for pickup and delivery on the weekend, but
          orders are placed during the week. Do we show them as always open?
          Always closed? New variations?
        </li>
        <li>List delivery fees, especially for in house delivery.</li>
        <li>
          Add option to generate/share link with selected tags pre-selected
        </li>
      </ul>
    </div>
  </Layout>
);

export default RoadMap;
